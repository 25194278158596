<template>
    <b-container class="settings-tab bg-light page-wrapper">
        <b-row class="main-content-wrapper pb-0" no-gutters>
            <b-col>
                <settings-submenu></settings-submenu>
            </b-col>
        </b-row>
        <b-row class="main-content-wrapper pt-0 household-settings" no-gutters>
            <b-col sm="12" md="3" lg="3" class="detail-text text-left py-2 px-0 py-md-5 px-md-3 py-lg-5 px-lg-5">
                <h2 class="text-dark">{{ $t(translationPath + 'title') }}</h2>
                <p class="text-gray">{{ $t(translationPath + 'description') }}</p>
            </b-col>
            <b-col class="py-2 px-0 py-md-2 px-md-4 py-lg-2 px-lg-4">
              <household-settings/>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
/* eslint-disable camelcase */
import TitleMixins from '@/mixins/title'
import SettingsSubmenu from '../SettingsSubmenu'
import HouseholdSettings from './HouseholdSettings'

export default {
  name: 'Household',
  components: { SettingsSubmenu, HouseholdSettings },
  mixins: [TitleMixins],
  data () {
    return {
      translationPath: 'settings.tab_household.'
    }
  },
  computed: {
    titleMeta () {
      return this.$t('settings.submenu.household')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/settings.scss';
.household-settings {
    table {
      th {
        border-top: none;
      }
    }
    .custom-control-inline {
        margin-top: 6px;
    }
    .formTitle {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px dashed #b5b5b5;
    }
    .action-button-row {
      padding:0 ;
      border: none;
      line-height: normal;
      i {
        margin: 0;
        font-size: 13px;
      }
      &:hover {
        background: transparent;
      }
      &:active {
        background-color: transparent !important;
        border-color: transparent !important;
      }
      &:focus {
        background: transparent;
        box-shadow: none !important;
      }
    }
    .addPersonForm {
        border-bottom: 1px dashed #b5b5b5;
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;
        padding-bottom: 15px;
    }
    .vdp-datepicker {
      .is_invalid {
        border-color: #EF5350;
      }
      .is_valid {
        border-color: #36b37E;
      }
    }
    .personTables {
      padding: 20px 20px 10px;
      margin-top: 15px;
      background: #fff;
    }
}
</style>
